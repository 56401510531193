<template>
  <el-dialog
    class="timeslot-left-dialog"
    width="30%"
    title="Заполните данные для оплаты"
    :visible.sync="innerVisible"
    append-to-body
    :before-close="close"
  >
    <el-form
      label-width="300px"
      class="drivers-slots-form"
      label-position="top"
      @submit.native.prevent
    >
      <iq-input-form
        v-model="inn"
        label="ИНН поставщика"
        placeholder="Укажите ИНН поставщика"
      />
    </el-form>
    <span slot="footer" class="dialog-footer-second">
      <iq-button full-width color="second" size="small" @onClick="close">
        Отменить
      </iq-button>
      <iq-button size="small" @onClick="getNameByInn">
        Найти организацию
      </iq-button>
    </span>
  </el-dialog>
</template>

<script>
import { DR_FETCH_COMMON_SUPPLIERS_CHECK_INN } from '../../../store/actions'
import { mapActions } from 'vuex'
import IqButton from '../../ui/components/buttons/IqButton'
import IqInputForm from '../../ui/components/input/IqInputForm'
import IqInputSearchForm from '../../ui/components/input/IqInputSearchForm'

export default {
  components: { IqInputForm, IqButton },
  props: {
    innerVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      inn: null,
      name: null,
      id: null,
    }
  },
  methods: {
    ...mapActions({
      /**
       * Actions для терминала СТЕПЬ.
       */
      fetchCommonSuppliersCheckInn: DR_FETCH_COMMON_SUPPLIERS_CHECK_INN,
    }),
    async getNameByInn() {
      if (this.inn && this.inn.length >= 10) {
        let common_supplier = await this.fetchCommonSuppliersCheckInn(this.inn)

        this.id = common_supplier.id
        this.name = common_supplier.name
      }
    },

    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="sass">
$bg-modal: rgba(225, 225, 225, 0.82)
$bg-modal-header: #4CA7D8
$white: #fff
.timeslot-left-dialog
  background: $bg-modal

  .el-dialog
    border-radius: 4px!important
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1) !important
    @media(max-width: 1120px)
      width: 50%!important
    @media(max-width: 768px)
      width: 80%!important
    @media(max-width: 480px)
      width: 90%!important
  .el-dialog__body
    padding: 12px 15px!important
  .dialog-footer-second
    display: flex
    flex-wrap: nowrap
    align-items: center
    justify-content: space-between
    @media(max-width: 420px)
      flex-wrap: wrap
  .el-button
    @media(max-width: 420px)
      margin-left: 0px!important
      margin-bottom: 10px!important
      margin-top: 10px!important
  .el-dialog__headerbtn
    top: 13px!important
    right: 10px!important
  .el-dialog__close
    font-size: 24px!important
    color: $white!important
  .el-dialog__header
    background: $bg-modal-header!important
    padding: 12px 15px!important
    border-radius: 4px 4px 0px 0px!important
    > span
      font-style: normal!important
      font-weight: 500!important
      font-size: 18px!important
      line-height: 26px!important
      color: $white!important
</style>
