var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    {
      staticClass: "iq-input-search-form",
      staticStyle: { width: "100%" },
      attrs: {
        label: _vm.label,
        prop: _vm.prop,
        rules: _vm.rules,
        "label-width": _vm.labelWidth,
        "show-word-limit": "",
      },
    },
    [
      _c(
        "el-input",
        {
          class: { fullWidth: _vm.size === "fullWidth" },
          attrs: {
            disabled: _vm.disabled,
            loading: _vm.loading,
            placeholder: _vm.placeholder,
            "fetch-suggestions": _vm.options,
          },
          on: { input: _vm.handleInput },
          model: {
            value: _vm.inputVal,
            callback: function ($$v) {
              _vm.inputVal = $$v
            },
            expression: "inputVal",
          },
        },
        [
          _c("el-button", {
            attrs: {
              slot: "append",
              loading: _vm.loading,
              icon: "el-icon-search",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("search")
              },
            },
            slot: "append",
          }),
        ],
        1
      ),
      _vm.message
        ? _c("span", [_vm._v(" " + _vm._s(_vm.message) + " ")])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }