<template>
  <el-form-item
    class="iq-input-search-form"
    :label="label"
    :prop="prop"
    :rules="rules"
    :label-width="labelWidth"
    style="width: 100%"
    show-word-limit
  >
    <el-input
      v-model="inputVal"
      :disabled="disabled"
      :loading="loading"
      :placeholder="placeholder"
      :fetch-suggestions="options"
      :class="{ fullWidth: size === 'fullWidth' }"
      @input="handleInput"
    >
      <el-button
        slot="append"
        :loading="loading"
        icon="el-icon-search"
        @click="$emit('search')"
      />
    </el-input>
    <span v-if="message">
      {{ message }}
    </span>
  </el-form-item>
</template>

<script>
export default {
  name: 'IqInputSearchForm',
  props: {
    size: {
      tyype: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      require: true,
    },
    label: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    rules: {
      type: [Array, Object],
      default: () => ({}),
    },
    labelWidth: {
      type: String,
      default: 'auto',
    },
    inputWidth: {
      type: String,
      default: 'auto',
    },
  },

  computed: {
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('onInput', event)
    },
  },
}
</script>
<style lang="sass">
$color-blue-primary: #4CA7D8

.iq-input-search-form
  .info-message
    font-size: 11px
    color: $btn-orange!important
  .el-form-item__label
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: normal !important
    font-size: 14px !important
    line-height: 22px
    color: #606266
  .el-form-item__label:before
    content: '' !important
  .el-select-dropdown
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1))
    border: none
    box-shadow: none
  .el-select-dropdown__item.selected
    font-family: Roboto, sans-serif
    font-style: normal
    font-weight: 500
    font-size: 14px
    line-height: 22px
    display: flex
    align-items: center
    font-feature-settings: 'tnum' on, 'lnum' on
    color: $color-blue-primary
  &.el-form-item
    margin-bottom: 12px!important

    .el-form-item__label
      margin-bottom: 5px !important
      text-align: left!important
      line-height: 22px!important
      font-weight: 600!important
      font-size: 14px!important
      width: var(--label-width) !important
      padding: 0 !important
      color: #909399!important

    .el-icon-arrow-up:before
      content: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.75 22.625L12.5 16.375L6.25 22.625' stroke='%23C0C4CC' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important
.fullWidth
  width: 100%!important
</style>
